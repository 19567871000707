<template>
    <div v-if="!isSubmitted">
        <h3 class="text-white mb-6 font-bold text-2xl uppercase">Get CAFA Updates In Your Inbox</h3>

        <form class="subscribe-form mb-12" @submit.prevent="submit" method="post" novalidate>

            <div class="row">
                <div class="px-6 md:pl-6 md:pr-3 w-full md:w-1/2 pb-4">
                    <label for="firstName"class="sr-only">First Name</label>
                    <input type="text" name="firstName" id="firstName" placeholder="FIRST NAME" class="form-input" v-model.lazy.trim="form.firstName" @blur="onFieldBlur('firstName')" v-bind:class="getFieldClasses('firstName')">
                    <div v-if="isErrorField('firstName')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('mailingForm.firstName') }) }}</div>
                </div>

                <div class="px-6 md:pr-6 md:pl-3 w-full md:w-1/2 pb-4">
                    <label for="lastName"class="sr-only">Last Name</label>
                    <input type="text" name="lastName" id="lastName" placeholder="LAST NAME" class="form-input" v-model.lazy.trim="form.lastName" @blur="onFieldBlur('lastName')" v-bind:class="getFieldClasses('lastName')">
                    <div v-if="isErrorField('lastName')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('mailingForm.lastName') }) }}</div>
                </div>

            </div>

            <div class="row">
                <div class="w-full px-6 pb-4">
                    <label for="email"class="sr-only">Email Address</label>
                    <input type="text" name="email" id="email" placeholder="EMAIL ADDRESS" class="form-input" v-model.lazy.trim="form.email" @blur="onFieldBlur('email')" v-bind:class="getFieldClasses('email')">
                    <div v-if="isErrorField('email')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('mailingForm.email') }) }}</div>
                </div>
            </div>
            <div class="row">
                <div class="w-full px-6">
                    <button type="submit" class="btn btn-blue-light btn-rounded" :disabled="submitting">
                        <span v-if="submitting">{{ $t('general.submitting' ) }} <!--<img src="../../img/loader.svg" />--></span>
                        <span v-else>SIGN ME UP!</span>
                    </button>
                </div>
            </div>
        </form>

    </div>
    <div v-else>
        <div>
            <div class="w-full text-left text-white">
                <h3 class="text-white mb-6 font-bold text-2xl uppercase">You've Been Added To Our Mailing List</h3>
                <p>Thanks for signing up for updates from CAFA.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { required, email } from 'vuelidate/lib/validators';
    import axios from 'axios';

  export default {
    name: 'mailing-form',
    data() {
      return {
        isSubmitted: false,
        isError: false,
        errorHeader: 'error.invalidFields',
        errors: [],
        submitting: false,
        form: {
          firstName: '',
          lastName: '',
          email: '',
		  jRpEplvM5: 1,
        }
      }
    },
    props: ['formHash','hptName', 'hptHash', 'payload'],
    methods: {
      submit() {
        this.$v.$touch();
        if (!this.$v.$error) {
          this.sendFormData();
        } else {
          this.validationError();
        }
      },
      enableSubmitLoader() {
        this.submitting = true;
      },
      disableSubmitLoader() {
        this.submitting = false;
      },
      sendFormData() {
        this.enableSubmitLoader();
        let formData = new FormData();
        let formInput = this.form;
        formData.append('action', 'freeform/submit');
        formData.append(this.$parent.csrfName, this.$parent.csrfTokenValue);
        formData.append('formHash', this.formHash);
        formData.append('freeform_payload', this.payload);
        //formData.append(this.form.hptName, this.form.hptHash);

        Object.keys(formInput).forEach(function (item) {
          formData.append(item, formInput[item]);
        });
        //let formData = JSON.stringify(this.form);
        axios.post('/', formData, {
          headers: {
            'X-CSRF-Token': this.$parent.csrfTokenValue,
            "X-Requested-With": "XMLHttpRequest",
            "HTTP_X_REQUESTED_WITH": "XMLHttpRequest",

          }
        }).then(response => {
          this.submitSuccess(response);
          this.disableSubmitLoader();
        }).catch(error => {
          this.submitError(error);
          this.disableSubmitLoader();
        });

      },
      submitSuccess(response) {
        if (response.data.success) {
          this.isSubmitted = true;
          this.isError = false;
        } else {
          this.errorHeader = 'error.invalidFields';
          this.errors = response.data.errors;
          this.isError = true;
        }
      },
      submitError(error) {
        this.errorHeader = 'error.general';
        this.errors = [{'field': null, 'message': 'error.generalMessage'}];
        this.isError = true;
      },
      validationError() {
        this.errorHeader = 'error.invalidFields';
        this.errors = this.getErrors();
        this.isError = true;
      },
      isErrorField(field) {
        try {
          if (this.getValidationField(field).$error) {
            return true;
          }
        } catch (error) {}

        return this.errors.some(el => el.field === field);
      },
      getErrors() {
        let errors = [];
        for (const field of Object.keys(this.form)) {
          try {
            if (this.getValidationField(field).$error) {
              errors.push({'field': field, 'message': null});
            }
          } catch (error) {}
        }
        return errors;
      },
      getFieldClasses(field) {
        return { 'is-invalid': this.isErrorField(field) }
      },
      getValidationField(field) {
        if (this.$v.form[field]) {
          return this.$v.form[field];
        }
        throw Error('No validation for field ' + field);
      },
      onFieldBlur(field) {
        try {
          this.getValidationField(field).$touch();
          if (this.getValidationField(field).$error) {
            if (!this.errors.some(el => el.field === field)) {
              this.errors.push({'field': field, 'message': null});
            }
          } else {
            this.errors = this.errors.filter(el => el.field !== field);
          }
        } catch (error) {}
      },
      reload() {
        window.location = '';
      }
    },
    validations: {
      form: {
		firstName: { required },
		lastName: { required },
        email: { required, email }
      }
    },
    watch: {
      errors() {
        this.isError = this.errors.length > 0 ? true : false;
      }
    }
  }
</script>

