<template>
    <transition appear appear-class="fade-enter" appear-to-class="fade-enter-to" appear-active-class="fade-enter-active" v-on:after-appear="sequenceStart">
        <div class="home-page-banner" v-touch:swipe.left="nextClick"  v-touch:swipe.right="prevClick">
            <slot name="slides" :currentslide="this.currentslide" :currentanim="this.currentanim"></slot>

            <div class="slide-content-box-wrapper">
                <div class="slide-content-box">
                    <slot name="slidetext" :currentslide="this.currentslide"></slot>
                </div>

                <div class="slide-button-box">
                    <slot name="slidebutton" :currentslide="this.currentslide"></slot>
                </div>

                <div class="control-wrapper" v-if="this.slidecount > 1">
                    <button class="btn-prev-arrow" title="Previous Project" @click="prevClick">
                        <svg viewBox="0 0 12 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M3.085,0l2.908,7.235l-2.908,7.234l-3.085,0l2.907,-7.234l-2.907,-7.235l3.085,0Zm5.993,0l2.908,7.235l-2.908,7.234l-3.085,0l2.907,-7.234l-2.907,-7.235l3.085,0Z" style="fill:currentColor;fill-rule:nonzero;"></path></svg>
                    </button>
                    <button class="btn-next-arrow" title="Next Project" @click="nextClick">
                        <svg viewBox="0 0 12 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M3.085,0l2.908,7.235l-2.908,7.234l-3.085,0l2.907,-7.234l-2.907,-7.235l3.085,0Zm5.993,0l2.908,7.235l-2.908,7.234l-3.085,0l2.907,-7.234l-2.907,-7.235l3.085,0Z" style="fill:currentColor;fill-rule:nonzero;"></path></svg>
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
  let slideTimer = null;
  export default {
	name: "homeCarousel",
	data() {
	  return {
		currentslide: null,
		currentanim: 'fade-slide-left',
	  };
	},
	props: ['slidecount'],
	methods: {
	  nextClick: function() {
		this.nextSlide();
		clearInterval(slideTimer);
		slideTimer = setInterval(this.nextSlide, 8000);
	  },
	  prevClick: function() {
		this.prevSlide();
		clearInterval(slideTimer);
		slideTimer = setInterval(this.nextSlide, 8000);
	  },
	  nextSlide: function() {
		this.currentanim = 'fade-slide-left';
		if (this.currentslide == (this.slidecount - 1)) {
		  this.currentslide = 0;
		} else {
		  this.currentslide++;
		}
	  },
	  prevSlide: function() {
		this.currentanim = 'fade-slide-right';
		if (this.currentslide == 0) {
		  this.currentslide = this.slidecount - 1;
		} else {
		  this.currentslide--;
		}
	  },
	  sequenceStart: function() {

		this.currentslide = 0;
		slideTimer = setInterval(this.nextSlide, 8000);

	  }
	}

  }
</script>
