<template>
    <transition name="fade" mode="out-in">
        <div class="slide-content" v-show="this.slideid == currentslide">
            <slot name="slideheader"></slot>
            <slot name="slidetext"></slot>
        </div>
    </transition>
</template>

<script>
  export default {
	name: "homeCarouselContent",
	props: ['slideid','currentslide'],
  }
</script>
