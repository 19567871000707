import Vue from "vue";
import Components from "./components";
import "./app.css";
import Vue2TouchEvents from 'vue2-touch-events';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import translations from './resources/translations';
import axios from 'axios';
import VScrollLock from 'v-scroll-lock'


Vue.config.productionTip = false;

const main = async () => {

  // Async load the vue module

  Vue.use(VScrollLock);
  Vue.use(Vue2TouchEvents);
  Vue.use(VueI18n);
  Vue.use(Vuelidate);

  const i18n = new VueI18n({

    locale: 'en',
    fallbackLocale: 'en',
    messages: translations
  });

  // Create our vue instance
  const vm = new Vue({
    el: "#app",
    i18n,
    delimiters: ['[[', ']]'],
    data: {
      menuActive: false,
      modalActive: false,
      modalContent: '',
      tlModal: false,
      csrfTokenValue: '',
      csrfName: '',
    },
    methods: {
      closeModal: function() {
        this.modalActive = false;
      },
      afterLeave: function() {
        this.modalContent = '';
      },
      goToAction: function() {
        const wHeight = window.innerHeight;
        const scrollDist = window.pageYOffset;
        const gotoBtn = document.getElementById('go-to-top');
        if (scrollDist > wHeight) {
          gotoBtn.classList.add('scrolled');
        } else {
          gotoBtn.classList.remove('scrolled');
        }
      }
    },
    mounted() {
      const self = this;

      function getCsrf() {
        return axios.get('/forms/csrfgetter');
      }

      function getCsrfName() {
        return axios.get('/forms/csrfnamegetter');
      }

      axios.all([getCsrf(), getCsrfName()])
        .then(axios.spread(function (csrf, csrfName) {

          // Both requests are now complete
          self.csrfTokenValue = csrf.data.trim();
          self.csrfName = csrfName.data.trim();
        }));
    },
    created() {
      window.addEventListener('scroll', this.goToAction);
    }
  });

  return vm;
};

// Execute async function

const initMain = () => {
  // Execute async function
  main().then( (vm) => {
    document.body.style.visibility = 'visible';
  });
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initMain); // Document still loading so DomContentLoaded can still fire :)
} else {
  initMain();
}
