<template>
    <div v-if="!isSubmitted">
        <form class="contact-form mb-12" @submit.prevent="submit" method="post" novalidate>

            <div class="row">
                <div class="px-10 md:pl-10 md:pr-5 w-full md:w-1/2 pb-4">
                    <label for="firstName"class="uppercase font-bold text-navy mb-2">First Name</label>
                    <input type="text" name="firstName" id="firstName" class="form-input" v-model.lazy.trim="form.firstName" @blur="onFieldBlur('firstName')" v-bind:class="getFieldClasses('firstName')">
                    <div v-if="isErrorField('firstName')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('contactForm.firstName') }) }}</div>
                </div>

                <div class="px-10 md:pr-10 md:pl-5 w-full md:w-1/2 pb-4">
                    <label for="lastName"class="uppercase font-bold text-navy mb-2">Last Name</label>
                    <input type="text" name="lastName" id="lastName"  class="form-input" v-model.lazy.trim="form.lastName" @blur="onFieldBlur('lastName')" v-bind:class="getFieldClasses('lastName')">
                    <div v-if="isErrorField('lastName')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('contactForm.lastName') }) }}</div>
                </div>

            </div>

            <div class="row">
                <div class="px-10 md:pl-10 md:pr-5 w-full md:w-1/2 pb-4">
                    <label for="email"class="uppercase font-bold text-navy mb-2">Email Address</label>
                    <input type="text" name="email" id="email"  class="form-input" v-model.lazy.trim="form.email" @blur="onFieldBlur('email')" v-bind:class="getFieldClasses('email')">
                    <div v-if="isErrorField('email')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('contactForm.email') }) }}</div>
                </div>

                <div class="px-10 md:pr-10 md:pl-5 w-full md:w-1/2 pb-4">
                    <label for="phone"class="uppercase font-bold text-navy mb-2">Phone</label>
                    <input type="text" name="phone" id="phone" class="form-input" v-mask="'(###)###-####'" v-model.lazy.trim="form.phone" @blur="onFieldBlur('phone')" v-bind:class="getFieldClasses('phone')">
                    <div v-if="isErrorField('phone')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('contactForm.phone') }) }}</div>
                </div>
            </div>

            <div class="row">
                <div class="px-10 md:pl-10 md:pr-5 w-full md:w-1/2 pb-4">
                    <label for="iAmAn"class="uppercase font-bold text-navy mb-2">I Am A(n)</label>
                    <select name="iAmAn" id="iAmAn"  class="form-input" v-model.lazy.trim="form.iAmAn" @blur="onFieldBlur('iAmAn')" v-bind:class="getFieldClasses('iAmAn')">
                        <option value="">Choose One...</option>
                        <option value="Developer/Investor">Developer/Investor</option>
                        <option value="Public Official">Public Official</option>
                        <option value="Homebuyer">Homebuyer</option>
                        <option value="Lender/Realtor">Lender/Realtor</option>
                        <option value="Non-Profit">Non-Profit</option>
                        <option value="Other">Other</option>
                    </select>
                    <div v-if="isErrorField('iAmAn')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('contactForm.iAmAn') }) }}</div>
                </div>

                <div class="px-10 md:pr-10 md:pl-5 w-full md:w-1/2 pb-4">
                    <label for="iAmInterestedIn"class="uppercase font-bold text-navy mb-2">I Am Interested In</label>
                    <select name="iAmInterestedIn" id="iAmInterestedIn"  class="form-input" v-model.lazy.trim="form.iAmInterestedIn" @blur="onFieldBlur('iAmInterestedIn')" v-bind:class="getFieldClasses('iAmInterestedIn')">
                        <option value="">Choose One...</option>
                        <option value="Mortgage Rates">Mortgage Rates</option>
                        <option value="Loan Programs">Loan Programs</option>
                        <option value="Submitting an Investment Proposal">Submitting an Investment Proposal</option>
                        <option value="Becoming a Participating Lender">Becoming a Participating Lender</option>
                        <option value="Community Grants for Non-Profits">Community Grants for Non-Profits</option>
                        <option value="Other">Other</option>
                    </select>
                    <div v-if="isErrorField('iAmInterestedIn')" class="error-messages">{{ $t('error.fieldInvalid', { field: $t('contactForm.iAmInterestedIn') }) }}</div>
                </div>
            </div>

            <div class="row">
                <div class="form-group px-10 w-full pb-4">
                    <label for="message" class="uppercase font-bold text-navy mb-2">{{ $t('contactForm.message') }}*</label>
                    <textarea
                            type="message"
                            class="form-input"
                            id="message"
                            v-model.trim="form.message"
                            v-bind:class="getFieldClasses('message')"
                            @blur="onFieldBlur('message')">
                        </textarea>
                    <div v-if="isErrorField('message')" class="error-messages">{{  $t('error.fieldInvalid', { field: $t('contactForm.message') }) }}</div>
                </div>
            </div>
            <div class="row">
                <div class="w-full px-10">
                    <button type="submit" class="btn btn-blue-dark btn-rounded" :disabled="submitting">
                        <span v-if="submitting">{{ $t('general.submitting' ) }} <!--<img src="../../img/loader.svg" />--></span>
                        <span v-else>SUBMIT</span>
                    </button>
                </div>
            </div>
        </form>

    </div>
    <div v-else>
        <div>
            <div class="w-full text-left">
                <h3 class="text-blue-dark mb-4 font-bold text-2xl uppercase">Your Message Has Been Sent.</h3>
                <p>Thanks you for contacting CAFA. One of our representatives will be in touch with you shortly.</p>
            </div>
        </div>
    </div>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators';
  import axios from 'axios';
  import {mask} from 'vue-the-mask';

  export default {
	name: 'contact-form',
	data() {
	  return {
		isSubmitted: false,
		isError: false,
		errorHeader: 'error.invalidFields',
		errors: [],
		submitting: false,
		form: {
		  firstName: '',
		  lastName: '',
		  email: '',
		  phone: '',
		  iAmAn: '',
		  iAmInterestedIn: '',
      message: '',
		}
	  }
	},
  props: ['formHash','freeform_payload'],
	created: function () {
	  // `this` points to the vm instance
	  const self = this;
    /*
	  function getFormHash() {
		return axios.get('/forms/formhashgetter/contactForm');
	  }

    function getFormPayload() {
      return axios.get('/forms/payload/contactForm');
    }

	  axios.all([getFormHash(), getFormPayload()])
		.then(axios.spread(function (fHash, fPayload) {

		  // Both requests are now complete
		  self.form.formHash = fHash.data.trim();
      self.form.freeform_payload = fPayload.data;
		}));
    */
	},
	directives: {mask},
	methods: {
	  submit() {
		this.$v.$touch();
		if (!this.$v.$error) {
		  this.sendFormData();
		} else {
		  this.validationError();
		}
	  },
	  enableSubmitLoader() {
		this.submitting = true;
	  },
	  disableSubmitLoader() {
		this.submitting = false;
	  },
	  sendFormData() {
		this.enableSubmitLoader();
		let formData = new FormData();
		let formInput = this.form;
		formData.append('action', 'freeform/submit');
		formData.append(this.$parent.csrfName, this.$parent.csrfTokenValue);
    formData.append('formHash', this.formHash);
    formData.append('freeform_payload', this.freeform_payload);
		//formData.append(this.form.hptName, this.form.hptHash);

		Object.keys(formInput).forEach(function (item) {
		  formData.append(item, formInput[item]);
		});
		//let formData = JSON.stringify(this.form);
		axios.post('/', formData, {
		  headers: {
			'X-CSRF-Token': this.$parent.csrfTokenValue,
			"X-Requested-With": "XMLHttpRequest",
			"HTTP_X_REQUESTED_WITH": "XMLHttpRequest",

		  }
		}).then(response => {
		  this.submitSuccess(response);
		  this.disableSubmitLoader();
		}).catch(error => {
		  this.submitError(error);
		  this.disableSubmitLoader();
		});

	  },
	  submitSuccess(response) {
		if (response.data.success) {
		  this.isSubmitted = true;
		  this.isError = false;
		} else {
		  this.errorHeader = 'error.invalidFields';
		  this.errors = response.data.errors;
		  this.isError = true;
		}
	  },
	  submitError(error) {
		this.errorHeader = 'error.general';
		this.errors = [{'field': null, 'message': 'error.generalMessage'}];
		this.isError = true;
	  },
	  validationError() {
		this.errorHeader = 'error.invalidFields';
		this.errors = this.getErrors();
		this.isError = true;
	  },
	  isErrorField(field) {
		try {
		  if (this.getValidationField(field).$error) {
			return true;
		  }
		} catch (error) {}

		return this.errors.some(el => el.field === field);
	  },
	  getErrors() {
		let errors = [];
		for (const field of Object.keys(this.form)) {
		  try {
			if (this.getValidationField(field).$error) {
			  errors.push({'field': field, 'message': null});
			}
		  } catch (error) {}
		}
		return errors;
	  },
	  getFieldClasses(field) {
		return { 'is-invalid': this.isErrorField(field) }
	  },
	  getValidationField(field) {
		if (this.$v.form[field]) {
		  return this.$v.form[field];
		}
		throw Error('No validation for field ' + field);
	  },
	  onFieldBlur(field) {
		try {
		  this.getValidationField(field).$touch();
		  if (this.getValidationField(field).$error) {
			if (!this.errors.some(el => el.field === field)) {
			  this.errors.push({'field': field, 'message': null});
			}
		  } else {
			this.errors = this.errors.filter(el => el.field !== field);
		  }
		} catch (error) {}
	  },
	  reload() {
		window.location = '';
	  }
	},
	validations: {
	  form: {
		firstName: { required },
		lastName: { required },
		email: { required, email },
		phone: { required },
		iAmAn: { required },
		iAmInterestedIn: { required },
		message: { required },
	  }
	},
	watch: {
	  errors() {
		this.isError = this.errors.length > 0 ? true : false;
	  }
	}
  }
</script>

