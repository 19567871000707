<template>
    <div v-if="!isSubmitted">
        <div class="px-10">
        <form class="rates-form" @submit.prevent="submit" method="post" novalidate>
            <div class="row">
                <div class="w-full pb-4 md:w-1/3 lg:w-2/5 xl:w-2/6 md:text-right md:pb-0">
                    <h3 class="text-white text-xl pt-3 pb-2 mb-0">Receive Rates in Your Inbox</h3>
                </div>
                <div class="w-full pb-4 md:w-1/3 lg:w-2/5 xl:w-3/6 md:pb-0 md:px-3">
                    <label for="email2"class="sr-only">Email Address</label>
                    <input type="text" name="email2" id="email2" placeholder="EMAIL ADDRESS" class="form-input" v-model.lazy.trim="form.email2" @blur="onFieldBlur('email2')" v-bind:class="getFieldClasses('email2')">
                    <div v-if="isErrorField('email2')" class="error-messages pt-2">{{ $t('error.fieldInvalid', { field: $t('mailingForm.email') }) }}</div>
                </div>
                <div class="w-full md:w-1/3 lg:w-1/5 xl:w-1/6">
                    <button type="submit" class="btn btn-blue-light btn-rounded md:w-full md:pt-4 pb-3" :disabled="submitting">
                        <span v-if="submitting">{{ $t('general.submitting' ) }} <!--<img src="../../img/loader.svg" />--></span>
                        <span v-else>SUBSCRIBE</span>
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>
    <div v-else>
        <div>
            <div class="w-full text-left text-white">
                <h3 class="text-white mb-0 text-xl md:text-right">You've Been Added To Our Mailing List. <span style="white-space: nowrap">Thanks for</span> signing up for updates from CAFA.</h3>

            </div>
        </div>
    </div>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators';
  import axios from 'axios';

  export default {
	name: 'rate-subscribe-form',
	data() {
	  return {
		isSubmitted: false,
		isError: false,
		errorHeader: 'error.invalidFields',
		errors: [],
		submitting: false,
		form: {
		  email2: '',
		  '37zGdavJX': 1,
		}
	  }
	},
	props: ['formHash', 'payload'],
	methods: {
	  submit() {
		this.$v.$touch();
		if (!this.$v.$error) {
		  this.sendFormData();
		} else {
		  this.validationError();
		}
	  },
	  enableSubmitLoader() {
		this.submitting = true;
	  },
	  disableSubmitLoader() {
		this.submitting = false;
	  },
	  sendFormData() {
		this.enableSubmitLoader();
		let formData = new FormData();
		let formInput = this.form;
		formData.append('action', 'freeform/submit');
		formData.append(this.$parent.csrfName, this.$parent.csrfTokenValue);
		formData.append('formHash', this.formHash);
    formData.append('freeform_payload', this.payload);
		//formData.append(this.form.hptName, this.form.hptHash);

		Object.keys(formInput).forEach(function (item) {
		  formData.append(item, formInput[item]);
		});
		//let formData = JSON.stringify(this.form);
		axios.post('/', formData, {
		  headers: {
			'X-CSRF-Token': this.$parent.csrfTokenValue,
			"X-Requested-With": "XMLHttpRequest",
			"HTTP_X_REQUESTED_WITH": "XMLHttpRequest",

		  }
		}).then(response => {
		  this.submitSuccess(response);
		  this.disableSubmitLoader();
		}).catch(error => {
		  this.submitError(error);
		  this.disableSubmitLoader();
		});

	  },
	  submitSuccess(response) {
		if (response.data.success) {
		  this.isSubmitted = true;
		  this.isError = false;
		} else {
		  this.errorHeader = 'error.invalidFields';
		  this.errors = response.data.errors;
		  this.isError = true;
		}
	  },
	  submitError(error) {
		this.errorHeader = 'error.general';
		this.errors = [{'field': null, 'message': 'error.generalMessage'}];
		this.isError = true;
	  },
	  validationError() {
		this.errorHeader = 'error.invalidFields';
		this.errors = this.getErrors();
		this.isError = true;
	  },
	  isErrorField(field) {
		try {
		  if (this.getValidationField(field).$error) {
			return true;
		  }
		} catch (error) {}

		return this.errors.some(el => el.field === field);
	  },
	  getErrors() {
		let errors = [];
		for (const field of Object.keys(this.form)) {
		  try {
			if (this.getValidationField(field).$error) {
			  errors.push({'field': field, 'message': null});
			}
		  } catch (error) {}
		}
		return errors;
	  },
	  getFieldClasses(field) {
		return { 'is-invalid': this.isErrorField(field) }
	  },
	  getValidationField(field) {
		if (this.$v.form[field]) {
		  return this.$v.form[field];
		}
		throw Error('No validation for field ' + field);
	  },
	  onFieldBlur(field) {
		try {
		  this.getValidationField(field).$touch();
		  if (this.getValidationField(field).$error) {
			if (!this.errors.some(el => el.field === field)) {
			  this.errors.push({'field': field, 'message': null});
			}
		  } else {
			this.errors = this.errors.filter(el => el.field !== field);
		  }
		} catch (error) {}
	  },
	  reload() {
		window.location = '';
	  }
	},
	validations: {
	  form: {
		email2: { required, email }
	  }
	},
	watch: {
	  errors() {
		this.isError = this.errors.length > 0 ? true : false;
	  }
	}
  }
</script>

