export default {
    en: {
        general: {
            submitting: "Submitting"
        },
        contactForm: {
            firstName: "First Name",
            lastName: "Last Name",
            fromEmail: "Email Address",
            email: "Email Address",
            companyName: "Company Name",
            phone: "Phone Number",
            message: "Message",
            submitted: "Thank You for Contacting MMR",
            sentInfo: "Your message has been sent. We will go over it and get back in touch with you shortly.",
            return: "Return to the Contact Form"
        },
        mailingForm: {
            firstName: "First Name",
            lastName: "Last Name",
            email: "Email Address"
        },
        error: {
            invalidFields: "Following fields have an invalid or a missing value:",
            general: "An error happened during submit.",
            generalMessage: "Form sending failed due to technical problems. Try again later.",
            fieldRequired: "This field is required.",
            fieldInvalid: "This field is invalid or missing."
        }
    }
};
